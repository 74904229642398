<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-dialog-title>Danh sách phí dịch vụ kiện hàng: {{orderDetailCode}}</md-dialog-title>
        <md-content class="md-scrollbar">
            <div class="row">
                <div class="col l-12 m-12 c-12">
                    <div class="tool-bar">
                        <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm phí<md-tooltip>Thêm phí</md-tooltip></md-button>
                    </div>
                    <table class="data-table-2"> 
                        <thead> 
                            <tr> 
                                <th style="width:50px;">STT</th>
                                <th style="width:200px;">Mã phí</th> 
                                <th style="width:250px;">Tên phí</th> 
                                <th style="width:200px;">Loại phí</th> 
                                <th style="width:150px;">Số tiền</th> 
                                <th style="width:150px;">VAT</th> 
                                <th style="width:150px;">Thành tiền</th>
                                <th style="width:50px;">Hành động</th>
                            </tr> 
                        </thead> 
                        <tbody v-if="loadding == true" style="height: 100px;"> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false"> 
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                <td class="center">{{index + 1}}</td>
                                <td class="center">
                                    <div class="grid-control">
                                        <input v-model="item.fee.feeCode" class="form-control" type="text">
                                        <md-button @click="openFee(item.id)" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm phí</md-tooltip>
                                        </md-button>
                                    </div>
                                </td> 
                                <td class="center">
                                    {{item.fee.feeName}}
                                </td>
                                <td class="center" style="padding: 0 5px;">
                                    <md-radio v-model="item.type" v-for="type in types" :key="'type' + type.value" :value="type.value">{{type.text}}</md-radio>
                                </td>
                                <td class="center">
                                    <div class="group-control">
                                        <number @change="calSumItem(item)" v-model="item.amount" v-bind="currency" class="form-control currency"></number> 
                                    </div>
                                </td>
                                <td class="center">
                                    <div class="group-control">
                                        <number @change="calSumItem(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                    </div>
                                </td>
                                <td class="center">
                                    <div class="group-control">
                                        <number v-model="item.pay" v-bind="currency" class="form-control currency"></number> 
                                    </div>
                                </td>
                                <td class="action">
                                   <md-button v-on:click="delRow(item)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div>
            </div>
        </md-content>
        <md-dialog-actions>
            <md-button class="md-primary" @click="submit()">Lưu</md-button>
            <md-button class="md-primary" @click="close()">Đóng</md-button>
        </md-dialog-actions>
        <feeList ref="feeList" @close="closeFee"/>
    </md-dialog>
</template>

<script>
    import { mapActions } from 'vuex';
    import common from '../../mixins'; 
    import messageBox from '../../utils/messageBox';
    import feeList from '../../components/popup/_FeeList.vue';

    export default ({
        components: {
            feeList
        },
        data() {
            return {
                title: '',
                id:0,
                loadding: false,
                showDialog: false,
                submitted: false,
                data: [],
                orderId: 0,
                orderDetailId: 0,
                goodsId: 0,
                types: common.typeFee,
                vatF: common.vatF,
                currency: common.currencyF,
                orderDetailCode: '',
                revenueId: '',
                goods: null
            }
        },
        created(){
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSumItem(item){
                const selected = this.data.findIndex(x => x.id == item.id);
                let vat = parseFloat(this.data[selected].amount) * (parseFloat(this.data[selected].vat) / 100);
                this.data[selected].pay = Math.round(parseFloat(this.data[selected].amount) + vat);
            },

            delRow(item){
                const index = this.data.findIndex(x => x.id == item.id);
                this.data.splice(index, 1);
            },

            addRow(){
                let row = { id: 'goodsDetail_' + common.getFakeId(), revenueId: this.revenueId, orderId: this.orderId, orderDetailId: this.orderDetailId, goodsId: this.goodsId, orderDetail: { code: this.orderDetailCode }, goods: this.goods, feeId: 0, fee: { feeCode: '', feeName: '' }, type: 1,  amount: 0, vat: 0, pay: 0, note: '' };
                this.data.push(row);
            },

            open(revenueId, orderId, orderDetailId, goodsId, goods, orderDetailCode, data){
                this.revenueId = revenueId;
                this.goods = goods;
                let list = [];
                for(let i = 0; i < data.length; i++){
                    if( data[i].orderDetail.code === orderDetailCode){
                        list.push(data[i]);
                    }
                }
                this.orderDetailCode = orderDetailCode;
                this.orderId = orderId;
                this.orderDetailId = orderDetailId;
                this.goodsId = goodsId;
                this.data = list;
                this.showDialog = true;
                this.loadding = false;
            },

            close(){
                this.showDialog = false;
            },

            submit(){
                if(this.data !== undefined && this.data.length > 0){
                    this.data.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.data === undefined || this.data.length === 0){
                    messageBox.showWarning("Vui lòng thêm hành trình");
                }
                else{
                    this.$emit('close', this.data);
                    this.showDialog = false;
                }
            },

            //Fee
            closeFee(item){
                const selected = this.data.findIndex(x => x.id == this.selectedId);
                this.data[selected].feeId = item.id;
                this.data[selected].fee = item;
                this.$refs.feeList.close();
            },

            openFee(id){
                this.selectedId = id;
                this.$refs.feeList.open();
            },
        },
     })

</script>

<style lang="css" scoped>
    .md-content {
        max-width: 1200px;
        width: 1200px;
        max-height: auto;
        height: auto;
        overflow-y: auto;
        padding: 0 15px;
    }
    .dialog-form{
        padding: 15px;
    }
    .md-dialog-title {
        padding: 10px 24px 0 !important;
        margin-bottom: 0 !important;
    }
</style>
