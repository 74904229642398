<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button :disabled="entity.status > 1" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu thông tin (Ctrl + S)</md-tooltip></md-button>
                        <md-button :disabled="entity.status > 0" v-shortkey="['ctrl', 'l']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp"><span>L</span>ưu tạm thời<md-tooltip>Lưu tạm thời (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.revenueCode.$error }">
                               <label for="code">Mã phiếu</label>
                               <md-input name="code" v-model="entity.revenueCode"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.revenueCode.required">Vui lòng nhập mã phiếu</span>
                            </md-field>
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên phụ trách</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="orderName" @md-selected="getOrderselected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders" :class="{'md-invalid': submitted && $v.entity.orderId.$error }">
                                    <label>Đơn hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.orderId.isSelected">Vui lòng chọn đơn hàng</span>
                                </md-autocomplete>
                                <md-button @click="openOrder()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm đơn hàng</md-tooltip>
                                </md-button>
                            </div>
                            <md-field>
                               <label for="note">note</label>
                               <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>
                        </div>
                        
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.revenueDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.revenueDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.revenueDate.required">Vui lòng chọn ngày lập</span>
                            </md-datepicker>
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="note">Tổng phải thu</label>
                                        <md-input name="note" :value="formatNumber(entity.pay)" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="note">Phí dịch vụ</label>
                                        <md-input name="note" :value="formatNumber(entity.payFee)" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="note">Chi hộ</label>
                                        <md-input name="note" :value="formatNumber(entity.payOnBehalf)" ></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field class="number-control">
                                <div>Đã thu khách hàng:</div>
                                <number name="salarybase" v-model="entity.moneyReceived" v-bind="currency"></number> 
                            </md-field>
                            <md-field :class="{'md-invalid': submitted && $v.entity.status.$error }">
                                <label for="status">Trạng thái</label>
                                <md-select v-model="entity.status" name="type2" id="type2">
                                    <md-option v-for="item in status" :key="'status-' + item.value" :value="item.value">{{item.text}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="mode-view"> 
                                <div class="mode-view-item">
                                    <a @click="onModeView(1)" :class="{'mode-active': modeView == 1}">
                                        <md-icon>format_list_bulleted</md-icon>
                                        <md-tooltip>Hiện thị theo hàng hóa</md-tooltip>
                                    </a>
                                </div>
                                <div class="mode-view-item">
                                    <a @click="onModeView(2)" :class="{'mode-active': modeView == 2}">
                                        <md-icon>list_alt</md-icon>
                                        <md-tooltip>Hiển thị chi tiết</md-tooltip>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-detail" class="tab-content" md-label="Cước phí chung">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:150px;">Hành động</th>
                                                            <th style="width:10%;">Loại hình</th>
                                                            <th style="width:10%;">Loại hàng</th>
                                                            <th style="width:10%;">Đơn vị</th>
                                                            <th style="width:8%;">Số lượng</th> 
                                                            <th style="width:17%;">Tên phí</th>
                                                            <th style="width:10%;">Loại</th>
                                                            <th style="width:10%;">Số tiền</th>
                                                            <th style="width:5%;">VAT</th>
                                                            <th style="width:10%;">Thành tiền</th>
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="grid-action">
                                                                <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.transportType.title" :class="{'is-error': $v.entity.details.$each[index].transportTypeId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openTransportType(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm loại hình</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.goodsType.title" :class="{'is-error': $v.entity.details.$each[index].goodsTypeId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openGoodsType(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm loại hàng</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.unit.unitName" :class="{'is-error': $v.entity.details.$each[index].unitId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openUnit(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm đơn vị</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                {{item.quantity}}
                                                            </td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.fee.feeName" :class="{'is-error': $v.entity.details.$each[index].feeId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm phí</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <md-field>
                                                                    <md-select v-model="item.type" name="type2" id="type2">
                                                                        <md-option v-for="item in types" :key="'type2' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                    </md-select>
                                                                </md-field>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calCommon(item)" v-model="item.amount" :class="{'is-error': $v.entity.details.$each[index].amount.$error}" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calCommon(item)" v-model="item.vat" v-bind="vat" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number v-model="item.pay" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-goods" class="tab-content" md-label="Cước phí kiện hàng">
                                    <div v-if="modeView == 1" class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:7%;">Phí</th> 
                                                            <th style="width:15%;">Kiện hàng</th> 
                                                            <th style="width:25%;">Tên hàng</th> 
                                                            <th style="width:23%;">Phí dịch vụ</th> 
                                                            <th style="width:13%;">Thu hộ</th>
                                                            <th style="width:13%;">Tổng tiền</th>
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.goods" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="action">
                                                                <md-button @click="addGoodsFee(item)" class="md-fab md-mini md-blue">
                                                                    <md-icon>attach_money</md-icon>
                                                                    <md-tooltip>Phí dịch vụ</md-tooltip>
                                                                </md-button>
                                                            </td>
                                                            <td class="center">
                                                                {{item.orderDetail.code}}
                                                            </td> 
                                                            <td class="left">
                                                                {{item.goods.goodsName}}
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number v-model="item.payFee" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number v-model="item.payOnBehalf" v-bind="vat" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number v-model="item.pay" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addGoodsDetailRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:150px;">Hành động</th> 
                                                            <th style="width:15%;">Kiện hàng</th>
                                                            <th style="width:15%;">Tên hàng</th> 
                                                            <th style="width:20%;">Tên phí</th> 
                                                            <th style="width:10%;">Loại</th> 
                                                            <th style="width:10%;">Số tiền</th>
                                                            <th style="width:10%;">VAT</th>
                                                            <th style="width:10%;">Thành tiền</th>
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.goodsDetails" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="action">
                                                                <md-button v-on:click="delGoodsDetailRow(item)" class="md-fab md-mini md-plain">
                                                                    <md-icon>clear</md-icon>
                                                                    <md-tooltip>Xóa</md-tooltip>
                                                                </md-button>
                                                            </td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.orderDetail.code" class="form-control" type="text">
                                                                    <md-button @click="openGoods(item)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm kiện hàng</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="left">
                                                                <div v-if="item.orderDetailId > 0">{{item.goods.goodsName}}</div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.fee.feeName" class="form-control" type="text">
                                                                    <md-button @click="openFeeGoodsDetail(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm phí</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <md-field>
                                                                    <md-select v-model="item.type" name="type" id="type">
                                                                        <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                    </md-select>
                                                                </md-field>
                                                            </td>
                                                            
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calSum(item)" v-model="item.amount" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calSum(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number v-model="item.pay" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <orderList ref="orderList" @close="closeOrder"/>
        <feeList ref="feeList" @close="closeFee"/>
        <feeGoodsDetailList ref="feeGoodsDetailList" @close="closeFeeGoodsDetail"/>
        <goodsList ref="goodsList" @close="closeGoods"/>
        <goodsFeeList ref="goodsFeeList" @close="closeGoodsFee"/>
        <transportTypeList ref="transportTypeList" title="Loại hình" @close="closeTransportType"/>
        <goodsTypeList ref="goodsTypeList" @close="closeGoodsType"/>
        <unitList ref="unitList" @close="closeUnit"/>
   </div>
</template>
<script>
    import revenueService from '../../api/revenueService';
    import orderService from '../../api/orderService';
    import staffService from '../../api/staffService';
    import messageBox from '../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../mixins';
    import staffList from '../../components/popup/_StaffList.vue';
    import orderList from '../../components/popup/_OrderList.vue';
    import feeList from '../../components/popup/_FeeList.vue';
    import feeGoodsDetailList from '../../components/popup/_FeeList.vue';
    import goodsList from '../../components/popup/_GoodsOrderList.vue';
    import goodsFeeList from './GoodsFeeList.vue';
    import settingDebtService from '../../api/settingDebtService';
    import transportTypeList from '@/components/popup/_TransportPlanList.vue';
    import goodsTypeList from '@/components/popup/_TransportTypeList.vue';
    import unitList from '@/components/popup/_UnitList.vue';

    export default ({
        components: {
            staffList,
            orderList,
            feeList,
            feeGoodsDetailList,
            goodsList,
            goodsFeeList,
            transportTypeList,
            goodsTypeList,
            unitList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật phải thu đơn hàng'
        },
        data() {
            return {
                title: '',
                id: 0,
                modeView: 1,
                selectedId: '',
                selectedItem: null,
                submitted: false,
                staffName: '',
                staffs: [],
                orderName: '',
                orders: [],
                orderCode: '',
                types: common.typeFee,
                loadding: false,
                entity: { id: 0, moneyReceived: 0, payFee: 0, payOnBehalf: 0, goods: [], details: [], goodsDetails: [], revenueCode: '', revenueDate: common.dateNow, orderId: 0, staffId: 0, status: 0, amount: 0, vat: 0, pay: 0, note: ''},
                detail: { id: 'id_' + common.getFakeId(), orderId: 0, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, amount: 0, vat: 0, pay: 0, note: '' },
                goodsDetail: { id: 'goodsDetail_' + common.getFakeId(), orderDetail: { code: '' }, goods: { goodsName: '' }, feeId: 0, orderId: 0, orderDetailId: '', goodsId: 0, fee: { feeCode: '', feeName: '' }, type: 1,  amount: 0, vat: 0, pay: 0, note: '' },
                vatF: common.vatF,
                currency: common.currencyF,
                amount: 0,
                vat: 0,
                pay: 0,
                amount2: 0,
                vat2: 0,
                pay2: 0,
                status: [ { value: 0, text: 'Chưa xử lý' }, { value: 1, text: 'Đang thu' }, { value: 2, text: 'Quyết toán' } ]
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật phải thu đơn hàng';
               this.getById();
            }
            else{
               this.title = 'Thêm mới phải thu đơn hàng';
               this.getByAccount();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            onModeView(view){
                this.modeView = view;
            },

            calSummary(){
                let payFee = 0;
                let payOnBehalf = 0;
                for (var i = 0; i < this.entity.goods.length; i++) {
                    let item = this.entity.goods[i];
                    payFee = parseFloat(payFee) + parseFloat(item.payFee);
                    payOnBehalf = parseFloat(payOnBehalf) + parseFloat(item.payOnBehalf);
                }

                for (var j = 0; j < this.entity.details.length; j++) {
                    let item = this.entity.details[j];
                    if(parseFloat(item.type) === 1){
                        payFee = parseFloat(payFee) + parseFloat(item.pay);
                    }
                    else{
                        payOnBehalf = parseFloat(payOnBehalf) + parseFloat(item.pay);
                    }
                }

                this.entity.pay = parseFloat(payOnBehalf) + parseFloat(payFee);
                this.entity.payFee = parseFloat(payFee);
                this.entity.payOnBehalf = parseFloat(payOnBehalf);
            },

            calSumGoods(){
                for (var j = 0; j < this.entity.goods.length; j++) {
                    let payFee = 0;
                    let payOnBehalf = 0;
                    for (var i = 0; i < this.entity.goodsDetails.length; i++) {
                        let item = this.entity.goodsDetails[i];
                        if(parseFloat(this.entity.goods[j].orderDetailId) === parseFloat(item.orderDetailId)){
                            if(parseFloat(item.type) === 1){
                                payFee = parseFloat(payFee) + parseFloat(item.pay);
                            }
                            else{
                                payOnBehalf = parseFloat(payOnBehalf) + parseFloat(item.pay);
                            }
                        }
                    }
                    this.entity.goods[j].payFee = payFee;
                    this.entity.goods[j].payOnBehalf = payOnBehalf;
                    this.entity.goods[j].pay = parseFloat(payOnBehalf) + parseFloat(payFee);
                }
                this.calSummary();
            },

            closeGoodsFee(data){
                for(let i = 0; i < this.entity.goodsDetails.length; i++){
                    if(this.entity.goodsDetails[i].orderDetailId === this.selectedItem.orderDetailId){
                        this.entity.goodsDetails.splice(i, 1);
                    }
                }
                for(let j = 0; j < data.length; j++){
                    let obj = data[j];
                    let item = { id: 'goodsDetail_' + common.getFakeId(), orderDetail: {code: obj.orderDetail.code}, goods: { goodsCode: obj.goods.goodsCode, goodsName: obj.goods.goodsName }, feeId: obj.feeId, orderId: obj.orderId, orderDetailId: obj.orderDetailId, goodsId: obj.goodsId, fee: obj.fee, type: obj.type,  amount: obj.amount, vat: obj.vat, pay: obj.pay, note: '' };
                    this.entity.goodsDetails.push(item);
                }
                this.calSumGoods();
                this.selectedId = 0;
                this.selectedItem = null;
            },
            
            addGoodsFee(item){
                this.selectedId = item.id;
                this.selectedItem = item;
                this.$refs.goodsFeeList.open(this.entity.id, item.orderId, item.orderDetailId, item.goodsId, item.goods, item.orderDetail.code, this.entity.goodsDetails);
            },

            calCommon(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                let vat = parseFloat(this.entity.details[selected].amount) * (parseFloat(this.entity.details[selected].vat) / 100);
                this.entity.details[selected].pay = Math.round(parseFloat(this.entity.details[selected].amount) + vat);
                this.calSummary();
            },

            calSum(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == item.id);
                let vat = parseFloat(this.entity.goodsDetails[selected].amount) * (parseFloat(this.entity.goodsDetails[selected].vat) / 100);
                this.entity.goodsDetails[selected].pay = Math.round(parseFloat(this.entity.goodsDetails[selected].amount) + vat);
                this.calSumGoods();
            },

            openGoods(item){
                this.selectedId = item.id;
                this.$refs.goodsList.open(this.entity.orderId);
            },

            closeGoods(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == this.selectedId);
                this.entity.goodsDetails[selected].goods = item.goods;
                this.entity.goodsDetails[selected].orderDetail = item;
                this.entity.goodsDetails[selected].orderDetailId = item.id;
                this.entity.goodsDetails[selected].goodsId = item.goodsId;
                this.entity.goodsDetails[selected].code = item.code;

                this.$refs.goodsList.close();
            },

            openFeeGoodsDetail(id){
                this.selectedId = id;
                this.$refs.feeGoodsDetailList.open(8);
            },

            closeFeeGoodsDetail(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == this.selectedId);
                this.entity.goodsDetails[selected].fee = item;
                this.entity.goodsDetails[selected].feeId = item.id;
                this.$refs.feeGoodsDetailList.close();
                this.selectedId = '';
            },

            addGoodsDetailRow(){
                let item = { id: 'goodsDetail_' + common.getFakeId(), orderDetail: { code: '', goods: { goodsName: '' } }, estimateId: 0, feeId: 0, jobId: 0, orderId: this.entity.orderId, orderDetailId: '', goodsRouteId: 0, goodsId: 0, fee: { feeCode: '', feeName: '' }, type: 1,  amount: 0, vat: 0, pay: 0, isApproved: true, note: '' };
                this.entity.goodsDetails.push(item);
            },
            
            delGoodsDetailRow(item){
                const index = this.entity.goodsDetails.findIndex(x => x.id == item.id);
                this.entity.goodsDetails.splice(index, 1);
            },

            addRow(){
                let item = { id: 'id_' + common.getFakeId(), unit: {unitName: ''}, unitId: 0, transportType: {title: ''}, transportTypeId: 0, goodsTypeId: 0, goodsType: { title: '' }, orderId: this.entity.orderId, estimateId: 0, fee: { feeCode: '', feeName: '' }, quantity: 1, feeId: 0, type: 1, amount: 0, vat: 0, vatAmount: 0, pay: 0, note: '' };
                this.entity.details.push(item);
            },
            
            delRow(item){
                const index = this.entity.details.findIndex(x => x.id == item.id);
                this.entity.details.splice(index, 1);
            },

            closeFee(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].fee = item;
                this.entity.details[selected].feeId = item.id;
                this.$refs.feeList.close();
                this.selectedId = '';
            },

            openFee(id){
                this.selectedId = id;
                this.$refs.feeList.open();
            },

            getGoods(orderId){
                this.setLoading(true);
                orderService.getGoods(orderId).then((response) => {
                    if(response.statusCode == 200){
                        for(let i = 0; i< response.data.length; i++){
                            let obj = response.data[i];
                            let item = { id: 'goodsDetail_' + common.getFakeId(), orderDetail: {code: obj.code, serial: obj.serial}, goods: { goodsCode: obj.goods.goodsCode, goodsName: obj.goods.goodsName }, feeId: 0, orderId: orderId, orderDetailId: obj.id, goodsId: obj.goodsId, fee: { feeCode: '', feeName: '' }, type: 1,  amount: 0, vat: 0, pay: 0, note: '' };
                            this.entity.goodsDetails.push(item);

                            let item2 = { id: 'goods_' + common.getFakeId(),orderId: obj.orderId, orderDetailId: obj.id, goodsId: obj.goodsId,  orderDetail: {code: obj.code, serial: obj.serial }, goods: { goodsCode: obj.goods.goodsCode, goodsName: obj.goods.goodsName }, payFee: 0, payOnBehalf: 0, pay: 0, note: ''};
                            this.entity.goods.push(item2);
                        }
                        this.getOrderGoodsFee(orderId);
                        this.getOrderFee(orderId);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getOrderGoodsFee(orderId){
                this.setLoading(true);
                revenueService.getOrderGoodsFeeById(orderId).then((response) => {
                    if(response.statusCode == 200){
                        for(let i = 0; i< response.data.length; i++){
                            let obj = response.data[i];
                            let item = { id: 'goodsDetail_' + common.getFakeId(), hasBill: obj.hasBill, billId: obj.billId, billNo: obj.billNo, billDate: obj.billDate, orderDetail:  obj.orderDetail, goods: obj.goods, feeId: obj.feeId, orderId: orderId, orderDetailId: obj.orderDetailId, goodsId: obj.goodsId, fee: obj.fee, type: obj.type,  amount: obj.amount, vat: obj.vat, pay: obj.pay, note: obj.note };
                            this.entity.goodsDetails.push(item);
                        }
                        this.calSumGoods();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getOrderFee(orderId){
                this.setLoading(true);
                revenueService.getOrderFeeById(orderId).then((response) => {
                    if(response.statusCode == 200){
                        for(let i = 0; i< response.data.length; i++){
                            let obj = response.data[i];
                            let item = { id: 'detail_' + common.getFakeId(), transportType: { title: '' }, transportTypeId: 0, goodsTypeId: 0, goodsType: { title: '' }, quantity: 1, unitId: 0, unit: { unitName: '' }, feeId: obj.feeId, orderId: orderId,  order: obj.order, fee: obj.fee, type: obj.type, amount: obj.amount, vat: obj.vat, vatAmount: obj.vatAmount, pay: obj.pay, note: obj.note };
                            this.entity.details.push(item);
                        }
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getSetingDebt(orderId){
                this.setLoading(true);
                settingDebtService.getSettingByOrder(orderId).then((response) => {
                    if(response.statusCode == 200){
                        console.log('setting', response.data)
                        for(let i = 0; i < response.data.length; i++){
                            let obj = response.data[i];
                            let item = { id: 'detail-' + common.getFakeId(), order: this.entity.order, feeId: obj.feeId, orderId: this.entity.orderId, fee: obj.fee, type: 1, transportType: obj.transportType, transportTypeId: obj.transportTypeId, goodsTypeId: obj.goodsTypeId, goodsType: obj.goodsType, quantity: obj.quantity, unitId: obj.unitId, unit: obj.unit, amount: obj.unitPrice, vat: obj.vat, vatAmount: obj.vatAmount, pay: obj.amount, note: '' };
                            this.entity.details.push(item);
                        }
                        this.getGoods(orderId);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            
            //orders
            closeOrder(item){
                this.orderName = item.orderCode;
                this.entity.orderId = item.id;
                this.$refs.orderList.close();
                this.getSetingDebt(item.id);
            },

            getOrderselected(val){
                this.entity.orderId = val.id;
                this.orderName = val.orderCode;
                this.getSetingDebt(val.id);
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm, orderCode: '' };
                orderService.getOrders(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Goods Type
            closeGoodsType(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].goodsType = item;
                this.entity.details[selected].goodsTypeId = item.id;
                this.$refs.goodsTypeList.close();
                this.selectedId = '';
            },

            openGoodsType(id){
                this.selectedId = id;
                this.$refs.goodsTypeList.open();
            },

            //Transport Type
            closeTransportType(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].transportType = item;
                this.entity.details[selected].transportTypeId = item.id;
                this.$refs.transportTypeList.close();
                this.selectedId = '';
            },

            openTransportType(id){
                this.selectedId = id;
                this.$refs.transportTypeList.open(6);
            },

            //Unit
            closeUnit(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].unit = item;
                this.entity.details[selected].unitId = item.id;
                this.$refs.unitList.close();
                this.selectedId = '';
            },

            openUnit(id){
                this.selectedId = id;
                this.$refs.unitList.open();
            },

            getCode(staffId){
                this.setLoading(true);
                revenueService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.revenueCode = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            
            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.staffName = response.data.fullName;
                            this.entity.deptId = response.data.department.id;
                            this.entity.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.details.length > 0){
                    this.entity.details = this.entity.details.filter(item => !(item.feeId == 0));
                    this.entity.details.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.goods.length > 0){
                    this.entity.goods = this.entity.goods.filter(item => !(item.feeId == 0));
                    this.entity.goods.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.goodsDetails.length > 0){
                    this.entity.goodsDetails = this.entity.goodsDetails.filter(item => !(item.feeId == 0));
                    this.entity.goodsDetails.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }

                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                revenueService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/revenue');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                revenueService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/revenue');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                revenueService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.orderName = response.data.order.orderCode;
                        this.staffName = response.data.staff.fullName;
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/revenue');
            }
        },
        watch: { 
            orderName: function (val) { 
                if(val == ''){
                    this.entity.orderId = 0;
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                revenueCode: { required },
                orderId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                revenueDate: { required },
                status: { required },
                details: {
                    $each: {
                        transportTypeId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        goodsTypeId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        unitId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        feeId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        amount: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
            }
        }
    })
</script>

<style scoped>
    .mode-view-item i {
        color: #666 !important;
    }

    .mode-view-item .mode-active  i {
        color: #448aff !important;
    }

    .mode-view {
        display: flex;
        justify-content: right;
        padding: 0 0 5px 0;
    }

    .mode-view .mode-view-item {
        cursor: pointer;
        padding-left: 10px;
    }
</style>